import React from "react"
import { graphql } from "gatsby"
import {IPage} from "../types";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {StaticImage} from "gatsby-plugin-image";
import {Col, Container, Media, Row} from "react-bootstrap";
import {FaQuoteLeft} from 'react-icons/fa'

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            <StaticImage src={'../img/omgevingsvisie/sloop-sunset-picture-id514270989@2x.png'} alt={'kinderen spelen'}/>
            <Container
                fluid={'xl'}
            >
                <div className={'my-5'}>
                    <span className={'title text-uppercase font-weight-bold'}>Duurzaamheid</span>
                    <h2>Samen werken aan een <span className={'text-primary'}>duurzame buurt</span></h2>
                </div>
                <Row>
                    <Col md={6}>
                        <p className={'lead my-5'}>
                            De Schelpenbuurt is een wijk waar de bewoners aandacht hebben voor het veranderende klimaat, voor de natuur en voor het opwekken en gebruiken van groene energie. Bij de bouw en aanleg van de wijk is al zoveel mogelijk gebruik gemaakt van duurzame materialen. In uw woning en in uw leefomgeving. U woont hier dan ook met een vooruitziende blik naar de toekomst.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span: 6, offset: 1}}>
                        <div className={'border-left my-5 pl-md-3'}>
                            <p>
                                Daarmee is de Schelpenbuurt een unieke woonlocatie. Iedereen doet wat, thuis en in de buurt. In deze groene wijk kunt u, als nieuwe bewoner, samen met uw wijkgenoten uw bijdrage leveren door een stap te zetten richting een duurzame(re) toekomst!
                            </p>
                        </div>
                        <section className={'my-5 pl-md-3'}>
                            <header>
                                <h6>Woning voor de toekomst</h6>
                            </header>
                            <p>
                                In de Schelpenbuurt is gekozen voor een betekenisvol duurzaam woningontwerp. Dit betekent bijvoorbeeld dat uw nieuwe woning wordt verwarmd door de aansluiting op een duurzaam warmtesysteem. Uw elektriciteit wekt u zelf op met de zonnepanelen die op het dak liggen. Bij de bouw van de woningen is ook rekening gehouden met klimaatveranderingen. In een langdurige warme periode zorgen bouwkundige aanpassingen ervoor dat de hitte in de woning zoveel mogelijk beperkt blijft.
                            </p>
                        </section>
                        <section className={'my-5 pl-md-3'}>
                            <header>
                                <h6>
                                    Help mee waar het kan
                                </h6>
                            </header>
                            <p>
                                Ook voor u als toekomstige bewoner in de Schelpenbuurt is het belangrijk om rekening te houden met klimaatverandering. Zoals met periodes van hitte en droogte, afgewisseld met hevige regenbuien. Dit kan door de inrichting van de ruimte bij en om uw huis aan te passen. Zo zorgen bomen voor beschutting tegen de zon en voor koelte in huis. Ook helpt het om de tuin voornamelijk in te richten met planten, struiken en gras. Belangrijk extra effect is dat het hemelwater makkelijker de bodem in dringt.
                            </p>
                        </section>
                    </Col>
                    <Col md={5}
                         className={'align-self-center'}
                    >
                        <StaticImage src={'../img/omgevingsvisie/GettyImages-1215302438@2x.png'} alt={'fietsen'}/>
                    </Col>
                </Row>
                <Row className={'my-5'}>
                    <Col md={{span: 6, offset: 1}}>
                        <Media>
                            <FaQuoteLeft className={'display-3 text-primary mr-4'} />
                            <Media.Body>
                        <span className={'h3 text-primary'}>
                        U merkt dat u met deze woning een grote stap zet richting duurzaam wonen
                        </span>
                            </Media.Body>
                        </Media>
                    </Col>
                </Row>
                <Row className={'my-5'}>
                    <Col md={{span: 6, offset: 1}}>
                        <div className={'pl-md-3'}>
                            <h6>
                                Win-win voor de toekomst
                            </h6>
                            <p>
                                Binnen de wijk is zoveel mogelijk gebruikgemaakt van gerecyclede materialen. Ook zijn de woningen gebouwd met aandacht voor CO2-arme productieprocessen. Ook voor uw woning zijn dit belangrijke aandachtspunten geweest en is hier in hoge mate op ingezet. Zonder dat u het merkt heeft u met deze woning al een grote stap gezet richting duurzaam wonen.
                            </p>
                            <h6>
                                Duurzame woning in een groene leefomgeving
                            </h6>
                            <p>
                                De Schelpenbuurt ligt eigenlijk op een eiland. U kunt dus heerlijk genieten van het uitzicht op en over het water maar ook van een rijke diversiteit aan bloemen, planten, vogels en insecten.
                            </p>
                            <p>
                                Door het openbaar groen goed in te richten en door de aanwezigheid van het water, worden de effecten van de klimaatverandering in de openbare ruimte zoveel mogelijk beperkt. Uw duurzame woning sluit hier dus perfect op aan.
                            </p>
                            <h6>
                                Samen dat extra stapje zetten
                            </h6>
                            <p>
                                Als we onze leefstijl willen verduurzamen, kunnen we dat individueel doen, maar leuker is om het samen met de hele buurt op te pakken. Bijvoorbeeld door een deelauto te gebruiken, gereedschap met elkaar te delen, gezamenlijke maaltijden te organiseren of met elkaar een moestuin of boomgaard te starten in de openbare ruimte. Zo wordt de Schelpenbuurt een gezellige buurt waar duurzaamheid belangrijk is en je elkaar stimuleert om net dat stapje extra te zetten.
                            </p>
                        </div>
                    </Col>
                    <Col md={5}>
                        <StaticImage src={'../img/omgevingsvisie/6D8A9151@2x.png'} alt={'Wim van der Es'}/>
                        <div className={'my-3'}>
                            <h6>Wim van der Es</h6>
                            <p>
                                Wethouder financiën en ruimtelijke ordening
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
}

interface PageQueryData {
    file: {
        childMarkdownRemark: IPage
    }
}

export const pageQuery = graphql`
    query {
        file(name: {eq: "omgevingsvisie"}) {
            childMarkdownRemark {
                ...Page
            }
        }
    }
`
